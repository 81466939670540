export const initializeAnalytics = () => {
    const gaId = process.env.APP_GA_ID;

    if (!gaId) {
        // eslint-disable-next-line no-console
        console.error('Google Analytics Measurement ID is missing!');
        return;
    }

    // Add the Google Analytics script tag dynamically
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
    document.head.appendChild(script);
    // Initialize Google Analytics
    script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            // eslint-disable-next-line prefer-rest-params
            window.dataLayer.push(arguments);
        }
        window.gtag = gtag;
        gtag('js', new Date());
        gtag('config', gaId);
    };
};

export const trackPageView = (path) => {
    if (window.gtag) {
        window.gtag('config', process.env.APP_GA_ID, {
            page_path: path,
        });
    }
};

export const trackEvent = (category, action, label = '') => {
    if (window.gtag) {
        window.gtag('event', action, {
            event_category: category,
            event_label: label,
        });
    } else {
        // eslint-disable-next-line no-console
        console.warn('Google Analytics is not initialized yet.');
    }
};
