/* eslint-disable */
/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import { createRoot } from 'react-dom/client';
import React from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import './sentry';

import './app.css';

// Import Boot app
import Boot from './containers/Boot/loadable';
// Import Language Provider

import TagManager from 'react-gtm-module'
import { initializeAnalytics } from './utils/analytics';

const tagManagerArgs = {
    gtmId: 'GTM-TPW3TS8'
}

TagManager.initialize(tagManagerArgs)


// Create redux store with history
const MOUNT_NODE = createRoot(
  document.getElementById("app")
);



// Default query config for react query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry(failureCount, error) {
        if (error.status === 404) return false;
        if (failureCount < 2) return false;
        return false;
      },
    },
  },
});

const isLocalHost = document.URL.includes('localhost');

const render = () => {
  MOUNT_NODE.render(
    <>
      <QueryClientProvider client={queryClient}>
        {initializeAnalytics()}
        <Boot></Boot>
        {isLocalHost && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </>
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['containers/App'], () => {
    root.unmount();
    render();
  });
}

let DEFAULT_LOCALE;
try {
  if (!window) {
    DEFAULT_LOCALE = 'en';
  } else {
    DEFAULT_LOCALE = 'ro';
  }
} catch (err) {
  DEFAULT_LOCALE = 'en';
}

render(DEFAULT_LOCALE);

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
// if (process.env.NODE_ENV === 'production') {
//   require('offline-plugin/runtime').install(); // eslint-disable-line global-require
// }
